@import "./vars";


@mixin scrollbars($size, $foreground-c, $foreground-c-hover, $background-color: mix($foreground-c, white,  50%), $radius: 0) {
}

/*Border Component END*/
.ova-menu-decor-icon{
  display: flex;
  align-items: center;
}

.abr_mnf_mobile__button{
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 999;
  width: 40px;
  height: 30px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  display: none;
  row-gap: 3px;
}

.abr_mnf_mobile__button-line{
  
  &::before, &::after{
  right: 0;
  position: absolute;
  content: '';
  width: 50px;
  height: 5px;
  background-color: var(--c-1);
  transition: transform 0.15s ease-in, top 0.15s ease-in 0.15s;
  }
}

.abr_mnf_blueSocial_vk {
  margin-left: 23px;
  width: 220px;
  text-decoration: none;
  border: 1.5px solid var(--c-2);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 60px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  & span{
    color: var(--c-2)
  };
}

.abr_mnf_blueSocial_vk:hover {
  box-shadow: 1px 1px 25px 10px rgba(146, 148, 248, 0.4);
}

.abr_mnf_blueSocial_vk:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(146, 148, 248, 0.4),
    transparent
  );
  transition: all 650ms;
}

.abr_mnf_blueSocial_vk:hover:before {
  left: 100%;
}




// @keyframes glowing {
//   0% { background-position: 0 0; }
//   50% { background-position: 400% 0; }
//   100% { background-position: 0 0; }
// }

.abr_mnf_music, .abr_mnf_story{
  box-sizing: border-box;
  border-radius: 7px;
  padding: 15px;
  background-color: var(--c-2);
}

.abr_mnf_header_logo{
  display: flex;
  align-items: center;
}

.ova-menu-decor-icon svg{
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

.abr_mnf_share{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  align-items: center;
}

.ya-share2__container.ya-share2__container_c-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_c-scheme_blackwhite .ya-share2__mobile-popup-badge{
  background-color: var(--c-1) !important;
}

.ya-share2__container_size_l .ya-share2__badge .ya-share2__icon{
  height: 60px!important;
  width: 60px!important;
  background-size: 60px 60px!important;
  border: 1px solid var(--c-2)!important;
  border-radius: 7px!important;
  margin-right: 10px!important;
  color: var(--c-2)!important;
}

.ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__mobile-popup-badge{
  background-color: transparent !important;
}

.ya-share2__container_mobile .ya-share2__badge{
  border-radius: 7px !important;
}

.abr_mnf_men{
  font-size: 34px;
  font-weight: 700;
  color: var(--c-1);
  position: relative;
  &::after{
    content: '';
    position: absolute;
    bottom: -15px;
    width: 110px;
    height: 5px;
    background: var(--c-1);
    display: block;
  }
}

.abr_mnf_boxof{
  box-sizing: border-box;
  margin-bottom: 40px;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  border: 3px solid var(--c-2);
}

/* can delete*/

.abr_mnf_card_name{
  margin-right: 170px;
}

.main-awesome_input-content{
  font-weight: 700;
   -webkit-text-fill-color: var(--c-2);
}


ul{
  padding: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 17px;
  background: var(--c-3);
  letter-spacing: 1.4px;
}

.abr_mnf_blueSocial{
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.abr_mnf_blueSite{
  display: flex;
  justify-content: center;
  column-gap: 60px;
}

.main-awesome_input{
  color: #00ffd5;
  width: 100%;
&-content{
  background: none;
  font-size: 25px;
  border: none;
  padding: 15px;
  width: 100%;
  &:hover,&:focus, &:active {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
  &::placeholder{
    font-weight: 700;
    color: var(--c-2);
  }
  &:focus::placeholder{
    font-size: 27px;
  }
}
}

.abr_mnf_letter__cnt{
  margin-top: 15px;
}

.abr_mnf_letter{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  &__link{
    font-weight: 400;
    margin-top: 0;
    color: var(--c-2);
    font-size: 20px;
    border: 1.5px solid var(--white);
    display: inline-block;
    width: 20px;
    height: 25px;
    text-align: center;
    padding: 5px;
    &:hover{
      border: 1px solid var(--c-1);
      border-radius: 5px;
    }
  }
  &__heading{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  &__title{
    margin: 0;
    color: var(--c-1);
    font-size: 25px;
  }
  &__logo{
    margin-left: 22px;
    fill: var(--c-1);
    width: 43px;
    height: 43px;
    stroke: var(--c-1);
  }
}

.abr_mnf_mobile__button {
  display: none;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  position: relative;
  z-index: 9999999;
  flex-direction: column;
  &-line{
      margin-bottom: 5px;
      margin-top: 5px;
      display: block;
      position: relative;
      width: 40px;
      height: 4px;
      border-radius: 10px;
      background-color: var(--c-1);
  }
}

.abr_mnf_blueContainer {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
}

.abr_mnf_sotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.abr_mnf_header{
  margin-top: 50px;
  margin-bottom: 60px;
  &_menu{
    display: flex;
    column-gap: 45px;
    position: relative;
  }
  &_menu a{
    color: var(--c-2);
    font-size: 24px;
    position: relative;
    &:hover::after{
      display: flex;
      justify-content: center;
      content: "";
      display: block;
      width: 110%;
      position: absolute;
      padding-top: 10px;
      border-bottom: 3px solid var(--c-2);
      z-index: 2;
      left: 50%;
      transform: translate(-50%, 0);
      }
    
   
  }
  &_inner{
    display: flex;
    justify-content: space-between;
  }
  &_logo-link{
    font-size: 35px;
    font-weight: 700;
    color: var(--c-2);
    text-transform: uppercase;
  }
}

.abr_mnf_blueWrapper{
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.abr_mnf_bluePopular{
  align-items: start;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  &__box{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_title{
    font-weight: 700;
    color: var(--c-2);
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}



.pages{
  outline-color: var(--c-2);
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  font-weight: 700;
  color: var(--c-1);
  & a{
    color: var(--c-2);
    &:hover{
      text-decoration: underline;
    }
  }

}

.abr_mnf_result-of-search:hover{
  text-transform: uppercase;
}

.active{
  display: flex !important;
}

.abr_mnf_result-of-search{
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  border: 2px solid var(--c-1);
  border-radius: 10px;
  margin-bottom: 15px;
  &_link{
    font-size: 22px;
    color: var(--c-2);
    margin-left: 20px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.main-awesome_search{
  width: 500px;
}

.abr_mnf{
  display: flex;
  flex-direction: column;
  p{
    font-size: 28px;
    text-align: center;
    color: #fff;
  }
  &_card_artist, &_card_tracklink{
    overflow: hidden;
    max-height: 20px;
  }
  &_card{
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
    font-size: 20px;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: 12px;
    border: 3px solid var(--c-2);
    margin-bottom: 30px;
    &_artist{
      margin-right: 5px;
    }
    &:hover{
      .dur{
         color: #0000ee;
      }  
      .abr_mnf_card_artist::after{
        color: #0000ee;
      }
    }   
  
  }
  &_card.pausing a.abr_mnf_card_link::before{
    content: url('../images/card-play.svg');
  }
  &_card.plays a.abr_mnf_card_link::before{
    content: url('../images/card-pause.svg');
  }
  &_card_like{
    height: 30px;
  }
  &_card .start {
    cursor: pointer;
} 
  &_card-subtext{
    position: absolute;
    right: 110px;
  }

  &_card_time{
    font-size: 15px;
    transform: translate(0, -50%);
    top: 50%;
    position: absolute;
    right: 10px;
    color: var(--c-1);
  }
  &_card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }
  &_card_artist, &_card_tracklink{
    color: var(--c-2);
    width: 100%;
    margin-right: 10px;
  }
  &_card_download{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 65px;
    width: 35px;
    fill: var(--c-2);
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translate(0, -50%);
    svg{
      width: 65px;
      height: 65px;
    }
  }
  &_card_link{
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 20px;
    color: var(--white-c);

    &::before {
      content: url('../images/card-play.svg');
      width: 70px;
      height: 70px;
    }
  }
}

.abr_mnf_player-logo{
  width: 55px;
  height: 55px;
}

.abr_mnf_player-title{
  column-gap: 15px;
  display: flex;
  align-items: center;
}

.abr_mnf_card_artist::after{
  content: ' / ';
  margin-left: 10px;
  font-size: 25px;
}

.abr_mnf_music{
  &__title{
    margin-bottom: 25px;
    display: flex;
    align-items: center;
  }
  &__title-heading{
    text-align: center;
    width: 100%;
    color: var(--c-3);
    font-size: 30px;
    border-bottom: 3px solid var(--c-3);
    padding-bottom: 10px;
  }
  &__title-logo{
    width: 32px;
    height: 36px;
    fill: var(--c-1);
    margin-right: 30px;
  }
}

.submit {
  cursor: pointer;
  cursor: pointer;
  transition: all .2s ease;
  border: none;
  background: 0 0;
  padding: 10px;
  svg {
    width: 35px;
    height: 35px;
    stroke: var(--c-2);
    fill: var(--c-2);
  }
}

.dur {
  font-size: 22px;
  color: var(--c-2);
}

.title {
 overflow: hidden;
 line-height: 35px;
}

.abr_mnf_logo-link{
  font-size: 35px;
  font-weight: 700;
  color: var(--c-1);
}

.abr_mnf_footer{
  margin-top: 10px;
  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--c-1);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.abr_mnf_player-link{
  font-size: 30px;
  color: #fff;
}

.abr_mnf_story_track-title{
  overflow: hidden;
  display: flex;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 7px;
  border: 4px solid var(--c-3);
  background-color: var(--c-3);
  color: var(--c-2);
  width: 100%;
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  text-wrap: wrap;
  &:hover{
    background-color: var(--c-2);
    border: 3px solid var(--c-3);
    color: var(--c-3);
  }
}

.abr_mnf_story{
  &_heading{
    text-align: center;
    color: var(--c-3);
    font-size: 30px;
    position: relative;
    border-bottom: 4px solid var(--c-3);
    padding-bottom: 20px;;
  }
  &_track-link{
    width: 100%;
    font-size: 22px;
    display: flex;
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--white-c);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.f-1{
  margin-bottom: 150px;
}

.abr_mnf_description{
  width: 450px;
  font-size: 22px;
  &_content{
    display: flex;
    justify-content: center;
    color: var(--c-2);
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    justify-content: space-between;
    row-gap: 30px;
    align-items: center;
  }
  &_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  &_title{
    display: flex;
    color: var(--c-2);
    display: flex;
    flex-wrap: wrap;
    font-size: 24px;
    } 
  }

.abr_mnf_boxline{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.abr_mnf_copyright_box{
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    column-gap: 15px;
    justify-content: center;
    color: var(--c-1);
    display: flex;
    font-size: 32px;
    align-items: center;
    a{
      color: var(--c-1);
      font-size: 32px;
      font-weight: 700;
    }
  }
  &-link{
    color: var(--c-14);
  }
}

.abr_mnf_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--c-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top p{
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--white-c);
    text-align: center;
  }
}

 .abr_mnf_sotial-network-vk {
  &_btn{
    border-radius: 7px;
    height: 80px;
    background-color: var(--c-1);
    width: 100%;
    font-size: 23px;
    text-align: center;
    font-weight: 700;
    color: var(--c-2);
    text-transform: uppercase;
    border: 4px solid var(--c-2);
    &::placeholder{
      color: var(--c-2);
    }
  }
  &_link{
    margin-top: 30px;
    border-radius: 7px;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    border: 4px solid var(--c-2);
    color: var(--c-2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: c 150ms ease-in-out;
    transition: c 150ms ease-in-out;
    height: 80px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--c-1);

      z-index: -1;
      -webkit-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;
    }
    &:hover::after{
      width: 110%;
    }
  }
  &_input{
    padding: 0 15px;
    margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
  &_content{
    line-height: 2;
    font-size: 20px;
    color: var(--c-2);
    font-weight: 700;
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--c-1);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--c-1);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.abr_mnf_story_track{
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.abr_mnf_genre{
  &-list{
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
  }
  &-item{
    transition: .5s ease;
    display: flex;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 7px;
    border: 4px solid var(--c-3);
    background-color: var(--c-3);
    width: 100%;
    text-align: center;
    justify-content: center;
    letter-spacing: 1.2px;
    &:hover{
      transition: .5s ease;
      background-color: var(--c-2);
      border: 3px solid var(--c-3);
      color: var(--c-3);
      .abr_mnf_genre-item_link{
        color: var(--c-3);
      }
    }
    &_link{
      font-size: 22px;
      color: var(--c-2);
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--c-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

#main-awesome_search__form {
  display: flex;
  align-items: center;
  border-bottom: 2px solid var(--c-2);
  height: auto;
  width: 100%;
}



.pleer {
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  margin: 0 auto;
  background-color: var(--c-3);
}



.abr_mnf_volume {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 17px;
  margin-top: 0;
  border-radius: 5px;
  z-index: 200;
  position: relative;
  background: var(--c-2);
  .abr_mnf_volume__line {
    position: absolute;
    top: 0;
    cursor: pointer;
    height: 17px;
    float: left;
    -webkit-transition: width 1s ease;
    transition: width 1s ease;
    background: var(--c-1);
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--c-6);
}

a.play {
  color: var(--white-c);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--white-c);
}


.play,.pause{
  display: flex;
  align-items: center;
  justify-content: center;
}
.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 90px;
    height: 90px;
    margin: 0;
  }
}
.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 90px;
    height: 90px;
    margin: 0;
  }
}

.abr_mnf_track-central_left-btn,
.abr_mnf_track-central_right-btn{
  svg{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  }
}




.abr_mnf_track-central_left-btn svg{
  transform: rotate(180deg);
}

.uil{
  cursor: pointer;
}

.abr_mnf_track-central{
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  margin-right: 20px;
}

.progressBarContainer-playBar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--c-2);
  color: var(--c-2);
}

.progressBarContainer--downloadBar{
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar{
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  display: inline-block;
  height: 15px;
  color: var(--c-2);
  align-items: center;
  transition: opacity .3s ease-in-out;
  background: var(--c-1);
  position: absolute;
  border-radius: 0 5px 5px 0;

  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-c);
    border: 2px solid #061161;
    display: none;
  }
}



.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
}

.track__track-timebox {
  position: absolute;
  width: 100%;
  height: 15px;
  background: var(--c-2);
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 25px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.abr_mnf_zero-time {
  font-size: 14px;
}

.abr_mnf_timeBox {
  margin-right: 30px;
  font-size: 14px;
}

.abr_mnf_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 22px;
    margin-left: 0;
    color: var(--c-2);
}

.abr_mnf_mute-box {
  height: 90px;
  margin-left: 20px;
  width: 250px;
  justify-content: flex-end;
  display: flex;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--white-c);
    }
  }

  &__muteUnmute{
    column-gap: 20px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}


.abr_mnf_time{
  &_space{
    font-weight: 700;
    margin: 0 5px;
  }
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--white);
    position: absolute;
    font-weight: 700;
    left: 20px;
    z-index: 2;
    top: 0;
    bottom: 0;
    align-items: center;
}

.abr_mnf_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .player__Collapse{
    display: none;
  }
}

.abr_mnf_playerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.abr_mnf_xplayer-title{
  width: 100%;
  display: flex;
  align-items: center;
}

.abr_mnf_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--c-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 60px;
  height: 60px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.abr_mnf_mute,
.abr_mnf_unmute{
  svg{
    position: relative;
    display: inline-block;
    width: 60px;
    height: 60px;
  }
}
.abr_mnf_mute{
  fill: var(--c-1);
}
.abr_mnf_unmute{
  fill: none;
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--c-3);
    }

    &:hover {
      background-color: var(--c-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--c-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.abr_mnf_shareblock{
    border-radius: 15px;
    z-index: 10000;
    width: 500px;
    display: block;
    background-color: var(--c-11);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--c-2);
    b {
      border: 4px solid var(--c-1);
      position: absolute;
      top: -20px;
      right: -20px;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #fff;
      cursor: pointer;
      border-radius: 30px;
      line-height: 40px;
      font-size: 25px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--c-11);
      font-family: sans-serif;
      font-size: 25px;
      font-weight: 400;
      display: block;
      color: var(--c-1);
      border-bottom: 4px solid var(--white-c);
    }
    span {
      width: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 25px 0;    
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      color: var(--c-1);
      font-size: 25px;
      background: url(../images/share.png) 10px 0 no-repeat;
      margin: 10px 10px 10px 0;
      padding: 0 10px 0 25px;
      &.vk {
        background-position: 0 4px;
      }
      &.tw {
        background-position: 0 -56px;
      }
      &.ok {
        background-position: 0 -86px;
      }
      &.mm {
        background-position: 0 -116px;
      }
      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}

.abr_mnf_active__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--c-1) !important;
    }
    .abr_mnf_active__selection-like{
      fill: var(--c-1);
    }
  }
  a{
    color: var(--c-14);
  }
}
  &-like{
    fill: var(--c-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}

.abr_mnf_blueSidebar{
  flex-direction: column;
  width: 450px;
  row-gap: 60px;
  display: flex;
  &_list{
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }
  & ul{
    font-weight: 700;
    margin-bottom: 0;
  }
  .abr_mnf_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--c-10);
    &_logo{
      a{
        color: var(--white-c);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--white-c);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--white-c) !important;
    &:hover {
      background: var(--c-10) !important;
      color: var(--white-c) !important;
    }
  }

}


